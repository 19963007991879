import AgentSubAgentApplyExchange from "../views/agent/AgentSubAgentApplyExchange";

const Login = () => import('../views/user/Login');
const Register = () => import('../views/user/Register');
const Main = () => import('../views/user/Main');
const SportsOriginal = () => import('../views/sports/SportsOriginal');
const SportsOriginalSpecial = () => import('../views/sports/SportsOriginalSpecial');
const SportsGameResult = () => import('../views/sports/SportsGameResult');
const SportsBetInfo = () => import('../views/sports/SportsBetInfo');
const UserInfo = () => import('../views/user/UserInfo');
const UserInfoRef = () => import('../views/user/UserInfoRef');
const CustomerCenter = () => import('../views/user/CustomerCenter');
const Board = () => import('../views/user/Board');
const Notice = () => import('../views/user/Notice');
const Event = () => import('../views/user/Event');
const BetRule = () => import('../views/user/BetRule');
const BoardDetail = () => import('../views/user/BoardDetail');
const EventDetail = () => import('../views/user/EventDetail');
const CulChek = () => import('../views/user/CulCheck');
const Rullet = () => import('../views/user/Rullet');
const Coupon = () => import('../views/user/Coupon');
const LiveTV = () => import('../views/user/LiveTV');
const Recharge = () => import('../views/user/Recharge');
const Exchange = () => import('../views/user/Exchange');
const Message = () => import('../views/user/Message');
const LeisureGame = () => import('../views/leisuregame/LeisureGame');
const LeisureGameBetInfo = () => import('../views/leisuregame/LeisrueGameBetInfo');
const LeisureGameResult = () => import('../views/leisuregame/LeisureGameResult');
const CasinoSlotGame = () => import('../views/casino/CasinoSlotGame');
const CasinoSlotGameHonor = () => import('../views/casino/CasinoSlotGameHonor');
const CasinoSlotGameReach = () => import('../views/casino/CasinoSlotGameReach');
const CasinoSlotGameOracle = () => import('../views/casino/CasinoSlotGameOracle');
const CasinoSlotGameNxzone = () => import('../views/casino/CasinoSlotGameNxzone');
const SportsInplay = () => import('../views/inplay/SportsInplay');
const Tgame365 = () => import('../views/leisuregame/Tgame365');
const ThirdPartGameBetInfo = () => import('../views/casino/ThirdpartGameBetInfo');
const Tgame365CasinoSlotGame = () => import('../views/casino/Tgame365CasinoSlotGame');
const ReachCasinoBetInfo = () => import('../views/casino/ReachCasinoBetInfo');
const HonorCasinoBetInfo = () => import('../views/casino/HonorCasinoBetInfo');
const AgentCode = () => import('../views/user/AgentCode');
const SportsTV = () => import('../views/sports/SportsTV');

/*관리자*/
const ManagerLogin = () => import('../views/administrator/ManagerLogin');
const MainStatistic = () => import('../views/administrator/MainStatistic');
const MainStatisticSimple = () => import('../views/administrator/MainStatisticSimple');
const ManagerSportsGame = () => import('../views/administrator/ManagerSportsGame');
const ManagerLeisureGame = () => import('../views/administrator/ManagerLeisureGame');
const ManagerUser = () => import('../views/administrator/ManagerUser');
const ManagerSportsBet = () => import('../views/administrator/ManagerSportsBet');
const ManagerLeisureGameBet = () => import('../views/administrator/ManagerLeisureGameBet');
const ManagerCasinoBetXimax = () => import('../views/administrator/ManagerCasinoBetXimax');
const ManagerCasinoBetHonor = () => import('../views/administrator/ManagerCasinoBetHonor');
const ManagerCasinoBetReach = () => import('../views/administrator/ManagerCasinoBetReach');
const ManagerTgame365Bet = () => import('../views/administrator/ManagerTgame365Bet');
const ManagerApplyRecharge = () => import('../views/administrator/ManagerApplyRecharge');
const ManagerApplyExchange = () => import('../views/administrator/ManagerApplyExchange');
const ManagerUserGroup = () => import('../views/administrator/ManagerUserGroup');
const ManagerRank = () => import('../views/administrator/ManagerRank');
const ManagerPartner = () => import('../views/administrator/ManagerPartner');
const ManagerAgent = () => import('../views/administrator/ManagerAgent');
const ManagerRegisterCode = () => import('../views/administrator/ManagerRegisterCode');
const ManagerCustomerCenter = () => import('../views/administrator/ManagerCustomerCenter');
const ManagerLeisureConfig = () => import('../views/administrator/ManagerLeisureConfig');
const ManagerMessage = () => import('../views/administrator/ManagerMessage');
const ManagerBoard = () => import('../views/administrator/ManagerBoard');
const ManagerCashPointLog = () => import('../views/administrator/ManagerCashPointLog');
const ManagerModifiedHistory = () => import('../views/administrator/ManagerModifiedHistory');
const ManagerLoginHistory = () => import('../views/administrator/ManagerLoginHistory');
const ManagerGameType = () => import('../views/administrator/ManagerGameType');
const ManageLeague = () => import('../views/administrator/ManageLeague');
const ManagerTeamNameMatching = () => import('../views/administrator/ManagerTeamNameMatching');
const ManagerSiteConfig = () => import('../views/administrator/ManagerSiteConfig');
const ManagerCrossRule = () => import('../views/administrator/ManagerCrossRule');
const ManagerCustomerTemplate = () => import('../views/administrator/ManagerCustomerTemplate');
const ManagerIP = () => import('../views/administrator/ManagerIP');
const ManagerPopup = () => import('../views/administrator/ManagerPopup');
const ManagerChangePasswd = () => import('../views/administrator/ManagerChangePasswd');
const ManagerLoginFailHistory = () => import('../views/administrator/ManagerLoginFailHistory');
const ManagerPhoneCode = () => import('../views/administrator/ManagerPhoneCode');
const ManagerDenied = () => import('../views/administrator/Denied');
const Administrator = () => import('../views/administrator/Administrator');

/*에이전트*/
const AgentLogin = () => import('../views/agent/AgentLogin');
const Agent = () => import('../views/agent/Agent');
const AgentMainStatistic = () => import('../views/agent/AgentMainStatistic');
const AgentMainStatisticSimple = () => import('../views/agent/AgentMainStatisticSimple');
const AgentSubList = () => import('../views/agent/AgentSubList');
const AgentUser = () => import('../views/agent/AgentUser');
const AgentApplyRecharge = () => import('../views/agent/AgentApplyRecharge');
const AgentApplyExchange = () => import('../views/agent/AgentApplyExchange');
const AgentCashPointLog = () => import('../views/agent/AgentCashPointLog');
const AgentUserCashPointLog = () => import('../views/agent/AgentUserCashPointLog');
const AgentSportsBet = () => import('../views/agent/AgentSportsBet');
const AgentLeisureGameBet = () => import('../views/agent/AgentLeisureGameBet');
const AgentCasinoBetHonor = () => import('../views/agent/AgentCasinoBetHonor');
const AgentCasinoBetXimax = () => import('../views/agent/AgentCasinoBetXimax');
const AgentMoveCash = () => import('../views/agent/AgentMoveCash');
const AgentExchangeCash = () => import('../views/agent/AgentExchangeCash');
const AgentTgame365Bet = () => import('../views/agent/AgentTgame365Bet');


let routes = [
    /*에이전트*/
    {
        path: '/partner',
        name: 'partner',
        component: AgentLogin,
        meta: {
            title: '에이전트 - 로그인'
        }
    },
    {
        path: '/agent',
        name: 'agent',
        component: Agent,
        children: [
            {
                path: 'agent_cash_exchange',
                name: 'agent_cash_exchange',
                component: AgentExchangeCash ,
                meta: {
                    title: '에이전트 환전신청',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_move_cash',
                name: 'agent_move_cash',
                component: AgentMoveCash ,
                meta: {
                    title: '에이전트 머니이동',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_casino_honor_bet',
                name: 'agent_casino_honor_bet',
                component: AgentCasinoBetHonor ,
                meta: {
                    title: '에이전트 H카지노 베팅내역',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_casino_ximax_bet',
                name: 'agent_casino_ximax_bet',
                component: AgentCasinoBetXimax ,
                meta: {
                    title: '에이전트 X카지노 베팅내역',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_leisurebet',
                name: 'agent_leisurebet',
                component: AgentLeisureGameBet ,
                meta: {
                    title: '에이전트 미니게임 베팅내역',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_sportsbet',
                name: 'agent_sportsbet',
                component: AgentSportsBet ,
                meta: {
                    title: '에이전트 스포츠 베팅내역',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_tgame365_bet',
                name: 'agent_tgame365_bet',
                component: AgentTgame365Bet ,
                meta: {
                    title: '에이전트 T365게임 베팅내역',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_logcash_list',
                name: 'agent_logcash_list',
                component: AgentCashPointLog ,
                meta: {
                    title: '에이전트 내캐쉬내역',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_user_cashpoint_history',
                name: 'agent_user_cashpoint_history',
                component: AgentUserCashPointLog ,
                meta: {
                    title: '회원 머니로그',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_subagent_exchange_list',
                name: 'agent_subagent_exchange_list',
                component: AgentSubAgentApplyExchange ,
                meta: {
                    title: '에이전트 하위에이전트출금목록',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_exchange_list',
                name: 'agent_exchange_list',
                component: AgentApplyExchange ,
                meta: {
                    title: '에이전트 회원출금목록',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_recharge_list',
                name: 'agent_recharge_list',
                component: AgentApplyRecharge ,
                meta: {
                    title: '에이전트 회원입금목록',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_user_list',
                name: 'agent_user_list',
                component: AgentUser ,
                meta: {
                    title: '에이전트 회원목록',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_sub_list',
                name: 'agent_sub_list',
                component: AgentSubList ,
                meta: {
                    title: '에이전트 리스트',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_main_statistic',
                name: 'agent_main_statistic',
                component: AgentMainStatistic ,
                meta: {
                    title: '에이전트 월별 통계',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.5',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_main_statistic_simple',
                name: 'agent_main_statistic_simple',
                component: AgentMainStatisticSimple ,
                meta: {
                    title: '에이전트 월별 통계',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.5',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: '/',
                redirect: 'agent_main_statistic_simple'
            },
        ]
    },


    /*관리자*/
    {
        path: '/manager',
        name: 'manager',
        component: ManagerLogin,
        meta: {
            title: '관리자 - 로그인',
            metaTags: [
                {
                    name: 'viewport',
                    content: 'width=1920, initial-scale=0.4',
                },
                // 추가적인 메타 태그들을 여기에 추가
            ],
        }
    },
    {
        path: '/administrator',
        name: 'administrator',
        component: Administrator,
        meta: {
            title: '홈 페이지',
        },
        children: [

            {
                path: 'manager_popup',
                name: 'manager_popup',
                component: ManagerPopup
                ,
                meta: {
                    title: '관리자-팝업 설정',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_phonecode',
                name: 'manager_phonecode',
                component: ManagerPhoneCode
                ,
                meta: {
                    title: '관리자-폰코드 ',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_ipcontainer',
                name: 'manager_ipcontainer',
                component: ManagerIP,
                meta: {
                    title: '관리자-IP 설정',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_custemp',
                name: 'manager_custemp',
                component: ManagerCustomerTemplate,
                meta: {
                    title: '관리자-템플렛 설정',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_crossrule',
                name: 'manager_crossrule',
                component: ManagerCrossRule,
                meta: {
                    title: '관리자-크로스 룰 설정',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_siteconfig',
                name: 'manager_siteconfig',
                component: ManagerSiteConfig,
                meta: {
                    title: '관리자-사이트설정',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_teamnamemaching',
                name: 'manager_teamnamemaching',
                component: ManagerTeamNameMatching,
                meta: {
                    title: '관리자-팀명매칭',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_league',
                name: 'manager_league',
                component: ManageLeague,
                meta: {
                    title: '관리자-리그관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_gametype',
                name: 'manager_gametype',
                component: ManagerGameType,
                meta: {
                    title: '관리자-종목관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_loglogin',
                name: 'manager_loglogin',
                component: ManagerLoginHistory,
                meta: {
                    title: '관리자-로그인 로그관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_modifiedhistory',
                name: 'manager_modifiedhistory',
                component: ManagerModifiedHistory,
                meta: {
                    title: '관리자-정보변경 로그관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_cashpointlog1',
                name: 'manager_cashpointlog1',
                component: ManagerCashPointLog,
                meta: {
                    title: '관리자-머니,포인트 로그관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_cashpointlog2',
                name: 'manager_cashpointlog2',
                component: ManagerCashPointLog,
                meta: {
                    title: '관리자-머니,포인트 로그관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_board1',
                name: 'manager_board1',
                component: ManagerBoard,
                meta: {
                    title: '관리자-공지,이벤트 관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_board2',
                name: 'manager_board2',
                component: ManagerBoard,
                meta: {
                    title: '관리자-공지,이벤트 관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_board3',
                name: 'manager_board3',
                component: ManagerBoard,
                meta: {
                    title: '관리자-공지,이벤트 관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_board4',
                name: 'manager_board4',
                component: ManagerBoard,
                meta: {
                    title: '관리자-게시판 관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_board5',
                name: 'manager_board5',
                component: ManagerBoard,
                meta: {
                    title: '관리자-공지,이벤트 관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_message',
                name: 'manager_message',
                component: ManagerMessage,
                meta: {
                    title: '관리자-쪽지 관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_config',
                name: 'manager_leisure_config',
                component: ManagerLeisureConfig,
                meta: {
                    title: '관리자-미니게임설정 관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_customer',
                name: 'manager_customer',
                component: ManagerCustomerCenter,
                meta: {
                    title: '관리자-고객센터 관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_register_code',
                name: 'manager_register_code',
                component: ManagerRegisterCode,
                meta: {
                    title: '관리자-가입코드 관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_partner',
                name: 'manager_partner',
                component: ManagerPartner,
                meta: {
                    title: '관리자-파트너 관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_agent',
                name: 'manager_agent',
                component: ManagerAgent,
                meta: {
                    title: '관리자-에이전트 관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_rank',
                name: 'manager_rank',
                component: ManagerRank,
                meta: {
                    title: '관리자-레벨관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_usergoup',
                name: 'manager_usergoup',
                component: ManagerUserGroup,
                meta: {
                    title: '관리자-그룹관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_exchange',
                name: 'manager_exchange',
                component: ManagerApplyExchange,
                meta: {
                    title: '관리자-출금관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_tgame365_bet',
                name: 'manager_tgame365_bet',
                component: ManagerTgame365Bet,
                meta: {
                    title: '관리자-토큰게임 베팅내역',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_casino_honor',
                name: 'manager_casino_honor',
                component: ManagerCasinoBetHonor,
                meta: {
                    title: '관리자-Honor카지노베팅내역',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_casino_ximax',
                name: 'manager_casino_ximax',
                component: ManagerCasinoBetXimax,
                meta: {
                    title: '관리자-Ximax카지노베팅내역',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_casino_reach',
                name: 'manager_casino_reach',
                component: ManagerCasinoBetReach,
                meta: {
                    title: '관리자-Reach카지노베팅내역',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisuregamebet',
                name: 'manager_leisuregamebet',
                component: ManagerLeisureGameBet,
                meta: {
                    title: '관리자-미니게임 베팅',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_sportsbet',
                name: 'manager_sportsbet',
                component: ManagerSportsBet,
                meta: {
                    title: '관리자-스포츠 베팅',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_user',
                name: 'manager_user',
                component: ManagerUser,
                meta: {
                    title: '관리자-회원정보관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_user2',
                name: 'manager_user2',
                component: ManagerUser,
                meta: {
                    title: '관리자-미인증회원',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_user3',
                name: 'manager_user3',
                component: ManagerUser,
                meta: {
                    title: '관리자-인증중회원',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game0',
                name: 'manager_leisure_game0',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game1',
                name: 'manager_leisure_game1',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game2',
                name: 'manager_leisure_game2',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game3',
                name: 'manager_leisure_game3',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game4',
                name: 'manager_leisure_game4',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game5',
                name: 'manager_leisure_game5',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game6',
                name: 'manager_leisure_game6',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game7',
                name: 'manager_leisure_game7',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game8',
                name: 'manager_leisure_game8',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game9',
                name: 'manager_leisure_game9',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game10',
                name: 'manager_leisure_game10',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game11',
                name: 'manager_leisure_game11',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game12',
                name: 'manager_leisure_game12',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game13',
                name: 'manager_leisure_game13',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game14',
                name: 'manager_leisure_game14',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game15',
                name: 'manager_leisure_game15',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game16',
                name: 'manager_leisure_game16',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game17',
                name: 'manager_leisure_game17',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game18',
                name: 'manager_leisure_game18',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game19',
                name: 'manager_leisure_game19',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_sports',
                name: 'manager_sports',
                component: ManagerSportsGame,
                meta: {
                    title: '관리자-스포츠경기',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_sports_1',
                name: 'manager_sports_1',
                component: ManagerSportsGame,
                meta: {
                    title: '관리자-스포츠경기',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_sports_2',
                name: 'manager_sports_2',
                component: ManagerSportsGame,
                meta: {
                    title: '관리자-스포츠경기',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_sports_3',
                name: 'manager_sports_3',
                component: ManagerSportsGame,
                meta: {
                    title: '관리자-스포츠경기',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_sports_4',
                name: 'manager_sports_4',
                component: ManagerSportsGame,
                meta: {
                    title: '관리자-스포츠경기',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_sports_5',
                name: 'manager_sports_5',
                component: ManagerSportsGame,
                meta: {
                    title: '관리자-스포츠경기',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_sports_6',
                name: 'manager_sports_6',
                component: ManagerSportsGame,
                meta: {
                    title: '관리자-스포츠경기',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_sports_7',
                name: 'manager_sports_7',
                component: ManagerSportsGame,
                meta: {
                    title: '관리자-스포츠경기',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_sports_8',
                name: 'manager_sports_8',
                component: ManagerSportsGame,
                meta: {
                    title: '관리자-스포츠경기',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'main_statistic',
                name: 'main_statistic',
                component: MainStatistic,
                //component: resolve => require(['../views/administrator/MainStatistic'], resolve),
                meta: {
                    title: '관리자-통계표',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'main_statistic_simple',
                name: 'main_statistic_simple',
                component: MainStatisticSimple,
                meta: {
                    title: '관리자-통계표',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_recharge',
                name: 'manager_recharge',
                component: ManagerApplyRecharge,
                meta: {
                    title: '관리자-입금관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_changepasswd',
                name: 'manager_changepasswd',
                component: ManagerChangePasswd,
                meta: {
                    title: '관리자-비밀번호 변경',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_logloginfail',
                name: 'manager_logloginfail',
                component: ManagerLoginFailHistory,
                meta: {
                    title: '관리자-로그인실패회원',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_denied',
                name: 'manager_denied',
                component: ManagerDenied,
                meta: {
                    title: '관리자-권한없음',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: '/',
                //redirect: 'main_statistic'
                redirect: 'main_statistic_simple'
            },

        ]
    },

    {
        path: '/',
        redirect: '/main'
    },
    {
        path: '/loginx',
        name: 'loginx',
        component: Login,
        meta: {
            title: '로그인'
        }
    },
    {
        path: '/agentcode',
        name: 'agentcode',
        component: AgentCode,
        meta: {
            title: '추천인 코드'
        }
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            title: '회원가입'
        }
    },
    {
        path: '/main',
        name: 'main',
        component: Main,
        meta: {
            title: '메인',
        }
    },
    {
        path: '/sports',
        name: 'sports',
        component: SportsOriginal,
        meta: {
            title: '스포츠-크로스',
            metaTags: [
                {
                    name: 'viewport',
                    content: 'width=device-width,initial-scale=1.0,user-scalable=no',
                },
                // 추가적인 메타 태그들을 여기에 추가
            ],
        }
    },
    {
        path: '/sports_special',
        name: 'sports_special',
        component: SportsOriginalSpecial,
        meta: {
            title: '스포츠-스페셜'
        }
    },
    {
        path: '/sports_inplay',
        name: 'sports_inplay',
        component: SportsInplay,
        meta: {
            title: 'Inplay'
        }
    },
    {
        path: '/sports_result',
        name: 'sports_result',
        component: SportsGameResult,
        meta: {
            title: 'Sports경기결과'
        }
    },
    {
        path: '/leisure_result',
        name: 'leisure_result',
        component: LeisureGameResult,
        meta: {
            title: 'Sports경기결과'
        }
    },
    {
        path: '/sports_betinfo',
        name: 'sports_betinfo',
        component: SportsBetInfo,
        meta: {
            title: '베팅내역(Sports)'
        }
    },
    {
        path: '/leisure_betinfo',
        name: 'leisure_betinfo',
        component: LeisureGameBetInfo,
        meta: {
            title: '베팅내역(Mini)'
        }
    },
    {
        path: '/customer_center',
        name: 'customer_center',
        component: CustomerCenter,
        meta: {
            title: '1:1문의'
        }
    },
    {
        path: '/board',
        name: 'board',
        component: Board,
        meta: {
            title: '게시판'
        }
    },
    {
        path: '/board_detail/:id',
        name: 'boardDetail',
        component: BoardDetail,
        meta: {
            title: '게시판'
        }
    },
    {
        path: '/notice',
        name: 'notice',
        component: Notice,
        meta: {
            title: '공지사항'
        }
    },
    {
        path: '/betrule',
        name: 'betrule',
        component: BetRule,
        meta: {
            title: '베팅규정'
        }
    },
    {
        path: '/event',
        name: 'event',
        component: Event,
        meta: {
            title: '이벤트'
        }
    },
    {
        path: '/event_detail/:id',
        name: 'eventDetail',
        component: EventDetail,
        meta: {
            title: '이벤트'
        }
    },
    {
        path: '/recharge',
        name: 'recharege',
        component: Recharge,
        meta: {
            title: '충전'
        }
    },
    {
        path: '/exchange',
        name: 'exchange',
        component: Exchange,
        meta: {
            title: '환전'
        }
    },
    {
        path: '/message',
        name: 'message',
        component: Message,
        meta: {
            title: '쪽지'
        }
    },
    {
        path: '/user_info',
        name: 'user_info',
        component: UserInfo,
        meta: {
            title: '내정보'
        }
    },
    {
        path: '/recmember',
        name: 'recmember',
        component: UserInfoRef,
        meta: {
            title: '내정보'
        }
    },
    {
        path: '/casino',
        name: 'CasinoSlotGameNxzone',
        component: CasinoSlotGameNxzone,
        meta: {
            title: 'Casino&SlotGame'
        }
    }    ,
    {
        path: '/casinooracel',
        name: 'CasinoSlotGameOracle',
        component: CasinoSlotGameOracle,
        meta: {
            title: 'Casino&SlotGame'
        }
    },
    {
        path: '/casinohonorx',
        name: 'CasinoSlotGameHonor',
        component: CasinoSlotGameHonor,
        meta: {
            title: 'Casino&SlotGame'
        }
    },
    {
        path: '/casinoreachx',
        name: 'CasinoSlotGameReach',
        component: CasinoSlotGameReach,
        meta: {
            title: 'Casino&SlotGame'
        }
    },
    {
        path: '/tgame365casino',
        name: 'Tgame365CasinoSlotGame',
        component: Tgame365CasinoSlotGame,
        meta: {
            title: 'Tgame365 Casino&SlotGame'
        }
    },
    {
        path: '/livetv',
        name: 'livetv',
        component: SportsTV,
        meta: {
            title: 'Live TV'
        }
    },
    {
        path: '/culcheck',
        name: 'culcheck',
        component: CulChek,
        meta: {
            title: '출석체크'
        }
    },
    {
        path: '/rullet',
        name: 'rullet',
        component: Rullet,
        meta: {
            title: '룰렛'
        }
    },
    {
        path: '/coupon',
        name: 'coupon',
        component: Coupon,
        meta: {
            title: '쿠폰'
        }
    },
    {
        path: '/honorcasino_betinfo',
        name: 'honorcasino_betinfo',
        component: HonorCasinoBetInfo,
        meta: {
            title: '카지노&슬롯 베팅내역'
        }
    },
    {
        path: '/reachcasino_betinfo',
        name: 'reachcasino_betinfo',
        component: ReachCasinoBetInfo,
        meta: {
            title: '카지노&슬롯'
        }
    },
    {
        path: '/thirdpartgame_betinfo',
        name: 'thirdpartgame_betinfo',
        component: ThirdPartGameBetInfo,
        meta: {
            title: '카지노&슬롯'
        }
    },
    {
        path: '/tgame365',
        name: 'tgame365',
        component: Tgame365,
        children: [
            {
                path: '/',
                redirect: 'tgame365highlow'
            },
            {
                path: 'tgame365highlow',
                name: 'leisure_detail_tgame365_highlow',
                component: resolve => require(['../views/leisuregame/children/Tgame365HighLow'], resolve),
                meta: {
                    title: '토큰게임 하이로'
                }
            },
            {
                path: 'tgame365baccarat',
                name: 'leisure_detail_tgame365_baccarat',
                component: resolve => require(['../views/leisuregame/children/Tgame365Baccarat'], resolve),
                meta: {
                    title: '토큰게임 하이로'
                }
            },
            {
                path: 'tgame365highlow5s',
                name: 'leisure_detail_tgame365_highlow5s',
                component: resolve => require(['../views/leisuregame/children/Tgame365HighLow5S'], resolve),
                meta: {
                    title: '토큰게임 하이로'
                }
            },
            {
                path: 'tgame365fw',
                name: 'leisure_detail_tgame365_fw',
                component: resolve => require(['../views/leisuregame/children/Tgame365FW'], resolve),
                meta: {
                    title: '토큰게임 포츈휠'
                }
            },
            {
                path: 'tgame365ruollet',
                name: 'leisure_detail_tgame365_ruollet',
                component: resolve => require(['../views/leisuregame/children/Tgame365Ruollet'], resolve),
                meta: {
                    title: '토큰게임 룰렛'
                }
            },
            {
                path: 'tgame365slot',
                name: 'leisure_detail_tgame365_slot',
                component: resolve => require(['../views/leisuregame/children/Tgame365Slot'], resolve),
                meta: {
                    title: '토큰게임 슬롯'
                }
            },
            {
                path: 'tgame365snail3',
                name: 'leisure_detail_tgame365_snail3',
                component: resolve => require(['../views/leisuregame/children/Tgame365Snail3'], resolve),
                meta: {
                    title: '달팽이 3줄'
                }
            },
            {
                path: 'tgame365snail4',
                name: 'leisure_detail_tgame365_snail4',
                component: resolve => require(['../views/leisuregame/children/Tgame365Snail4'], resolve),
                meta: {
                    title: '달팽이 4줄'
                }
            }
        ]
    },
    {
        path: '/leisure',
        name: 'leisure',
        component: LeisureGame,
        children: [
            {
                path: '/',
                redirect: 'bet365_premiership'
            },
            {
                path: 'npowersadali3s',
                name: 'leisure_detail_npowersadali3s',
                component: resolve => require(['../views/leisuregame/children/NamedNewPowerSadali3M'], resolve),
                meta: {
                    title: 'Named 파워사다리 3분'
                }
            },
            {
                path: 'npowersadali5s',
                name: 'leisure_detail_npowersadali5s',
                component: resolve => require(['../views/leisuregame/children/NamedNewPowerSadali5M'], resolve),
                meta: {
                    title: 'Named 파워사다리 5분'
                }
            },
            {
                path: 'npowerball3s',
                name: 'leisure_detail_npowerball3s',
                component: resolve => require(['../views/leisuregame/children/NamedNewPowerBall3M'], resolve),
                meta: {
                    title: 'Named 파워볼 3분'
                }
            },
            {
                path: 'npowerball5s',
                name: 'leisure_detail_npowerball5s',
                component: resolve => require(['../views/leisuregame/children/NamedNewPowerBall5M'], resolve),
                meta: {
                    title: 'Named 파워볼 5분'
                }
            },
            {
                path: 'powersadali',
                name: 'leisure_detail_powersadali',
                component: resolve => require(['../views/leisuregame/children/PowerSadali'], resolve),
                meta: {
                    title: '파워사다리'
                }
            },
            {
                path: 'speedkino',
                name: 'leisure_detail_speedkino',
                component: resolve => require(['../views/leisuregame/children/SpeedKino'], resolve),
                meta: {
                    title: '스피드키노'
                }
            },
            {
                path: 'kinosadali',
                name: 'leisure_detail_kinosadali',
                component: resolve => require(['../views/leisuregame/children/KinoSadali'], resolve),
                meta: {
                    title: '키노 사다리'
                }
            },
            {
                path: 'gamemoaace',
                name: 'leisure_detail_gamemoaace',
                component: resolve => require(['../views/leisuregame/children/GameMoaAce'], resolve),
                meta: {
                    title: 'Ace'
                }
            },

            {
                path: 'gamemoabubblesdl1',
                name: 'leisure_detail_gamemoabubblesdl1',
                component: resolve => require(['../views/leisuregame/children/GameMoaBubbleSadali1'], resolve),
                meta: {
                    title: '보글보글 사다리 1분'
                }
            },
            {
                path: 'gamemoabubblesdl3',
                name: 'leisure_detail_gamemoabubblesdl3',
                component: resolve => require(['../views/leisuregame/children/GameMoaBubbleSadali3'], resolve),
                meta: {
                    title: '보글보글 사다리 3분'
                }
            },
            {
                path: 'speedgamepdali',
                name: 'leisure_detail_speedgamepdali',
                component: resolve => require(['../views/leisuregame/children/SpeedGamePDALI'], resolve),
                meta: {
                    title: '황금돼지 사다리'
                }
            },
            {
                path: 'gamemoassd1',
                name: 'leisure_detail_gamemoassd1',
                component: resolve => require(['../views/leisuregame/children/GameMoaStartSadali1'], resolve),
                meta: {
                    title: 'GM별다리 1분'
                }
            },
            {
                path: 'gamemoassd5',
                name: 'leisure_detail_gamemoassd5',
                component: resolve => require(['../views/leisuregame/children/GameMoaStartSadali5'], resolve),
                meta: {
                    title: 'GM별다리 5분'
                }
            },
            {
                path: 'ssd1',
                name: 'leisure_detail_ssd1',
                component: resolve => require(['../views/leisuregame/children/StarSadali1'], resolve),
                meta: {
                    title: '별다리 1분'
                }
            },
            {
                path: 'ssd2',
                name: 'leisure_detail_ssd2',
                component: resolve => require(['../views/leisuregame/children/StarSadali2'], resolve),
                meta: {
                    title: '별다리 2분'
                }
            },
            {
                path: 'ssd3',
                name: 'leisure_detail_ssd3',
                component: resolve => require(['../views/leisuregame/children/StarSadali3'], resolve),
                meta: {
                    title: '별다리 3분'
                }
            },
            {
                path: 'nameddalidali',
                name: 'leisure_detail_nameddalidali',
                component: resolve => require(['../views/leisuregame/children/NamedDalidali'], resolve),
                meta: {
                    title: '네임드 다리다리'
                }
            },
            {
                path: 'namedsadali',
                name: 'leisure_detail_namedsadali',
                component: resolve => require(['../views/leisuregame/children/NamedSadali'], resolve),
                meta: {
                    title: '네임드 사다리'
                }
            },
            {
                path: 'namedspeedsadali',
                name: 'leisure_detail_namedspeedsadali',
                component: resolve => require(['../views/leisuregame/children/NamedSpeedSadali'], resolve),
                meta: {
                    title: '네임드 스피드 사다리'
                }
            },
            {
                path: 'namedsnail',
                name: 'leisure_detail_namedsnail',
                component: resolve => require(['../views/leisuregame/children/NamedSnail'], resolve),
                meta: {
                    title: '네임드 달팽이'
                }
            },
            {
                //Bet365SoccerPremiership
                path: 'bet365_premiership',
                name: 'leisure_detail_bet365_premiership',
                component: resolve => require(['../views/leisuregame/children/Bet365SoccerPremiership'], resolve),
                meta: {
                    title: 'Bet365 프리미어'
                }
            },
            {
                //Bet365SoccerSuperLeague
                path: 'bet365_superleague',
                name: 'leisure_detail_bet365_superleague',
                component: resolve => require(['../views/leisuregame/children/Bet365SoccerSuperLeague'], resolve),
                meta: {
                    title: 'Bet365 슈퍼리그'
                }
            },
            {
                //Bet365SoccerEuroCup
                path: 'bet365_eurocup',
                name: 'leisure_detail_bet365_eurocup',
                component: resolve => require(['../views/leisuregame/children/Bet365SoccerEuroCup'], resolve),
                meta: {
                    title: 'Bet365 유로컵'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'bet365_worldcup',
                name: 'leisure_detail_bet365_worldcup',
                component: resolve => require(['../views/leisuregame/children/Bet365SoccerWorldCup'], resolve),
                meta: {
                    title: 'Bet365 월드컵'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'bet365_goldenhill',
                name: 'leisure_detail_bet365_goldenhill',
                component: resolve => require(['../views/leisuregame/children/Bet365DogRaceGoldenHill'], resolve),
                meta: {
                    title: 'Bet365 개경주 골든힐'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'bet365_hillside',
                name: 'leisure_detail_bet365_hillside',
                component: resolve => require(['../views/leisuregame/children/Bet365DogRaceHillSide'], resolve),
                meta: {
                    title: 'Bet365 개경주 힐사이드'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'bet365_horserace',
                name: 'leisure_detail_horserace',
                component: resolve => require(['../views/leisuregame/children/Bet365HorseRace'], resolve),
                meta: {
                    title: '경마 트렌담 파크'
                }
            },
            {
                //
                path: 'bet365_horserace2',
                name: 'leisure_detail_horserace2',
                component: resolve => require(['../views/leisuregame/children/Bet365HorseRace2'], resolve),
                meta: {
                    title: '경마 브리타니아 웨이'
                }
            },
            {
                path: 'bet365_horserace3',
                name: 'leisure_detail_horserace3',
                component: resolve => require(['../views/leisuregame/children/Bet365HorseRace3'], resolve),
                meta: {
                    title: '경마 페스티벌 다운즈'
                }
            },
            {
                path: 'bet365_horserace4',
                name: 'leisure_detail_horserace4',
                component: resolve => require(['../views/leisuregame/children/Bet365HorseRace4'], resolve),
                meta: {
                    title: '경마 빅토리아 파크'
                }
            },
            {
                //Bet365SoccerSuperLeague
                path: 'bet365_water',
                name: 'leisure_detail_bet365_warter',
                component: resolve => require(['../views/leisuregame/children/Bet365BasketBallWaterLeague'], resolve),
                meta: {
                    title: 'Bet365 워터포트'
                }
            },
            {
                //Bet365SoccerSuperLeague
                path: 'bet365_baker',
                name: 'leisure_detail_bet365_baker',
                component: resolve => require(['../views/leisuregame/children/Bet365BasketBallBakerLeague'], resolve),
                meta: {
                    title: 'Bet365 워터포트'
                }
            },
            {
                path: 'powerball',
                name: 'leisure_detail_powerball',
                component: resolve => require(['../views/leisuregame/children/Powerball'], resolve),
                meta: {
                    title: '파워볼'
                }
            },
            {
                path: 'tgamepowerball',
                name: 'leisure_detail_tgamepowerball',
                component: resolve => require(['../views/leisuregame/children/Tgame365Powerball'], resolve),
                meta: {
                    title: '파워볼'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'powerball_lotto',
                name: 'leisure_detail_powerball_lotto',
                component: resolve => require(['../views/leisuregame/children/PowerballLotto'], resolve),
                meta: {
                    title: '파워볼 로또'
                }
            }
            ,
            {
                //크라운 섯다
                path: 'sutda',
                name: 'leisure_detail_sutda',
                component: resolve => require(['../views/leisuregame/children/CrownSutda'], resolve),
                meta: {
                    title: '크라운 섯다'
                }
            },
            {
                //크라운 나인볼
                path: 'nineball',
                name: 'leisure_detail_nineball',
                component: resolve => require(['../views/leisuregame/children/CrownNineBall'], resolve),
                meta: {
                    title: '크라운 섯다'
                }
            },
            {
                //로투스 바카라
                path: 'lotus_bakara',
                name: 'leisure_detail_lotus_bakara',
                component: resolve => require(['../views/leisuregame/children/LotusBakara'], resolve),
                meta: {
                    title: '로투스 바카라'
                }
            },
            {
                //로투스 바카라
                path: 'lotus_oddeven',
                name: 'leisure_detail_lotus_oddeven',
                component: resolve => require(['../views/leisuregame/children/LotusOddEven'], resolve),
                meta: {
                    title: '로투스 홀짝'
                }
            },
            {
                //로투스 바카라
                path: 'lotus_dragontiger',
                name: 'leisure_detail_lotus_dragontiger',
                component: resolve => require(['../views/leisuregame/children/LotusDragonTiger'], resolve),
                meta: {
                    title: '로투스 드래곤타이거'
                }
            },
            {
                //sky speed 바카라
                path: 'sky_speed_bakara',
                name: 'leisure_detail_sky_speed_bakara',
                component: resolve => require(['../views/leisuregame/children/SkySpeedBakara'], resolve),
                meta: {
                    title: '스카이파크 Speed 바카라'
                }
            },
            {
                //sky 홀짝
                path: 'sky_oddeven',
                name: 'leisure_detail_sky_oddeven',
                component: resolve => require(['../views/leisuregame/children/SkyOddEven'], resolve),
                meta: {
                    title: '스카이파크 홀짝'
                }
            },
            {
                //sky 주사위
                path: 'sky_dice',
                name: 'leisure_detail_sky_dice',
                component: resolve => require(['../views/leisuregame/children/SkyDice'], resolve),
                meta: {
                    title: '스카이파크 주사위'
                }
            },
            {
                //mgm ghfwkr
                path: 'next_power3m',
                name: 'leisure_detail_next_power3m',
                component: resolve => require(['../views/leisuregame/children/NextPowerBall3M'], resolve),
                meta: {
                    title: 'Next 파워볼 3분'
                }
            },
            {
                //mgm ghfwkr
                path: 'next_ring1m',
                name: 'leisure_detail_next_ring1m',
                component: resolve => require(['../views/leisuregame/children/NextRing1m'], resolve),
                meta: {
                    title: 'Next 1분링'
                }
            },
            {
                //mgm ghfwkr
                path: 'next_bakara',
                name: 'leisure_detail_next_bakara',
                component: resolve => require(['../views/leisuregame/children/NextBaccara'], resolve),
                meta: {
                    title: 'Next 바카라'
                }
            },
            {
                //mgm ghfwkr
                path: 'next_sadali',
                name: 'leisure_detail_next_sadali',
                component: resolve => require(['../views/leisuregame/children/NextSadali'], resolve),
                meta: {
                    title: 'Next 사다리'
                }
            },
            {
                //mgm ghfwkr
                path: 'mgm_bakara',
                name: 'leisure_detail_mgm_bakara',
                component: resolve => require(['../views/leisuregame/children/MgmBakara'], resolve),
                meta: {
                    title: 'mgm 바카라'
                }
            },
            {
                //mgm ghfwkr
                path: 'mgm_gostop',
                name: 'leisure_detail_mgm_gostop',
                component: resolve => require(['../views/leisuregame/children/MgmGostop'], resolve),
                meta: {
                    title: 'mgm 고스톱'
                }
            },
            {
                //mgm ghfwkr
                path: 'mgm_oddeven',
                name: 'leisure_detail_mgm_oddeven',
                component: resolve => require(['../views/leisuregame/children/MgmOddEven'], resolve),
                meta: {
                    title: 'mgm 홀짝'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'eospowerball1m',
                name: 'leisure_detail_eospowerball1m',
                component: resolve => require(['../views/leisuregame/children/EOSPowerBall1M'], resolve),
                meta: {
                    title: 'EOS 파워볼 1분'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'eospowerball3m',
                name: 'leisure_detail_eospowerball3m',
                component: resolve => require(['../views/leisuregame/children/EOSPowerBall3M'], resolve),
                meta: {
                    title: 'EOS 파워볼 3분'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'eospowerball5m',
                name: 'leisure_detail_eospowerball5m',
                component: resolve => require(['../views/leisuregame/children/EOSPowerBall5M'], resolve),
                meta: {
                    title: 'EOS 파워볼 5분'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'suremanpowerball1m',
                name: 'leisure_detail_suremanpowerball1m',
                component: resolve => require(['../views/leisuregame/children/SureManPowerBall1M'], resolve),
                meta: {
                    title: '슈어맨 파워볼 1분'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'suremanpowerball2m',
                name: 'leisure_detail_suremanpowerball2m',
                component: resolve => require(['../views/leisuregame/children/SureManPowerBall2M'], resolve),
                meta: {
                    title: '슈어맨 파워볼 2분'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'suremanpowerball3m',
                name: 'leisure_detail_suremanpowerball1m',
                component: resolve => require(['../views/leisuregame/children/SureManPowerBall3M'], resolve),
                meta: {
                    title: '슈어맨 파워볼 3분'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'suremansadali1m',
                name: 'leisure_detail_suremansadali1m',
                component: resolve => require(['../views/leisuregame/children/SureManSadali1M'], resolve),
                meta: {
                    title: '슈어맨 사디리 1분'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'suremansadali2m',
                name: 'leisure_detail_suremansadali2m',
                component: resolve => require(['../views/leisuregame/children/SureManSadali2M'], resolve),
                meta: {
                    title: '슈어맨 사디리 2분'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'suremansadali3m',
                name: 'leisure_detail_suremansadali3m',
                component: resolve => require(['../views/leisuregame/children/SureManSadali3M'], resolve),
                meta: {
                    title: '슈어맨 사디리 3분'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'hspowerball3m',
                name: 'leisure_detail_hspowerball3m',
                component: resolve => require(['../views/leisuregame/children/HSPowerBall3M'], resolve),
                meta: {
                    title: '하운슬로 파워볼 3분'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'hspowerball5m',
                name: 'leisure_detail_hspowerball5m',
                component: resolve => require(['../views/leisuregame/children/HSPowerBall5M'], resolve),
                meta: {
                    title: '하운슬로 파워볼 5분'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'pamgamemario',
                name: 'leisure_detail_pamgamemario',
                component: resolve => require(['../views/leisuregame/children/PamGameMario'], resolve),
                meta: {
                    title: '마리오'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'pamgameslot',
                name: 'leisure_detail_pamgameslot',
                component: resolve => require(['../views/leisuregame/children/PamGameSlot'], resolve),
                meta: {
                    title: '슬롯'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'pamgamedragontiger',
                name: 'leisure_detail_pamgamedragontiger',
                component: resolve => require(['../views/leisuregame/children/PamGameDragonTiger'], resolve),
                meta: {
                    title: 'Dragon&Tiger'
                }
            }


        ],
        meta: {
            title: '미니게임'
        }
    },

]

export default routes