<template>
  <!--오른쪽 메뉴-->
  <div class="user_box">
    <div v-if="$store.state.isLogin">
      <div class="logininfo">
        <div class="infoleft" style="display: flex;align-items: center;justify-content: center">
          <img :src="$store.state.userInfo.rank.imageUrl" width="80">
        </div>
        <div class="inforight">
          <div class="line underline" style="display: flex;justify-content: flex-start;align-items: center">
            <i class="fa fa-user" style="font-size: 16px;color: #ffffff;margin-right: 5px"></i>
            <span class="txt-nickname">{{ $store.state.userInfo.nickname }}</span>
            <router-link tag="button" class="btn-userinfo ml-5 " to="/user_info">회원정보</router-link>
            <button class="btn-loch ml-5" @click="logout" style="">로그아웃
            </button>
          </div>
          <div class="line underline text-white2">
            <img src="../assets/images/icon/cenha/icon-top-money.png" alt="" style="width: 18px">
            보유머니 <span
              class="txt-cash" @click="initUserInfo">{{ $store.state.userInfo.cash|comma }}</span> 원
            <i class="fa fa-refresh" @click="initUserInfo"></i>
            <!--                        <button @click="payback" class="btn-loch ml-5"-->
            <!--                                v-if="$store.state.userInfo.rank.payback&&$store.state.userInfo.rank.payback>0">페이백-->
            <!--                        </button>-->
          </div>
          <div class="line underline text-white2"
               style="display: flex;justify-content: flex-start;align-items: center">
            <img src="../assets/images/icon/cenha/icon-top-point.png" alt=""
                 style="width: 18px;margin-right: 3px">
            포인트 <span
              class="txt-cash ml-5">{{ $store.state.userInfo.userPoint|comma }}</span> 점
            <button @click="changePoint" class="btn-changepoint ml-5">전환
            </button>
          </div>
          <div class="line text-white" style="cursor: pointer;" @click="refreshCasinoCash()">
            <img src="../assets/images/icon/cenha/icon-top-casino.png" alt="" style="width: 18px">
            카지노칩 <span
              class="txt-cash">{{ $store.state.userInfo.casinocash|comma }}</span>
          </div>
          <div class="line underline text-white2">
            <img src="../assets/images/icon/cenha/icon-top-money.png" alt="" style="width: 18px">
            베팅중머니 <span
              class="txt-cash" @click="initUserInfo">{{ $store.state.userInfo.onbetmoney|comma }}</span> 원
          </div>

        </div>
      </div>
    </div>
    <div v-if="!$store.state.isLogin && !$store.state.isMaintaining"
         style="display: flex;justify-content: space-around;align-items: center;padding: 5px">
      <button class="btn_login" @click="showLoginPanel"><i class="fa fa-sign-in"></i> 로그인</button>
      <button class="btn_register" @click="showRegisterPanel"><i class="fa fa-user-circle"></i> 회원가입</button>
    </div>

    <promo-images-comp></promo-images-comp>
  </div>


</template>

<script>
import {logout, changePoint, login, getUserInfo} from "../network/userRequest";
//import {getAccountBalance} from "../network/casinoOracleRequest";
import {getAccountBalance} from "../network/casinoNxzoneRequest";
import {
  INIT_USERINFO,
  RECEIVE_HIDE_LOADING,
  RECEIVE_SHOW_LOADING,
  RECEIVE_USER_INFO
} from "../store/mutation-types";
import RightBarBannerComp from "./RightBarBannerComp";
import {loginRegisterMixin} from "../common/mixin";
import PromoImagesComp from "./PromoImagesComp";

export default {
  name: "UserInfoComp",
  mixins: [loginRegisterMixin],
  components: {PromoImagesComp, RightBarBannerComp},
  data() {
    return {
      isShowSubordinate: false,
      user: {
        username: '',
        passwd: '',
      },
    }
  },
  methods: {
    subordinate() {
      this.isShowSubordinate = !this.isShowSubordinate;
    },
    showLoginPanel() {

      if (!this.$store.state.isLogin && !this.$store.state.isShowLoginPanel) {
        this.$store.state.isShowLoginPanel = true
        this.$store.state.isShowRegisterPanel = false
      }
    },
    showRegisterPanel() {
      // if (!this.$store.state.isLogin) {
      //     this.$store.state.isShowRegisterPanel = false
      //     this.$store.state.isShowLoginPanel = false
      // }
      this.$store.state.isShowRegisterPanel = false
      this.$store.state.isShowLoginPanel = false
      this.$router.push('/agentcode')
    },
    doLogin() {
      // if (!this.testRegxUserName) {
      //     this.$swal({
      //         title: '아이디는 4-15자리 영문,숫자조합입니다',
      //         type: 'error',
      //         showCancelButton: false,
      //         showConfirmButton: true
      //     })
      //     return false;
      // }
      // if (!this.testRegxPasswd) {
      //     this.$swal({
      //         title: '비밀번호는 4-20자리 입니다',
      //         type: 'error',
      //         showCancelButton: false,
      //         showConfirmButton: true
      //     })
      //     return false;
      // }
      this.$store.commit(RECEIVE_SHOW_LOADING);
      login(this.user).then(res => {
        if (res.data.success) {
          getUserInfo().then(res => {
            this.$store.state.isLogin = true;
            const userInfo = res.data.data
            this.$store.commit(RECEIVE_USER_INFO, {userInfo});
            window.sessionStorage.setItem("isLogin", 'true');
            this.$store.commit(RECEIVE_HIDE_LOADING);
            this.$router.push('/main').catch(error => {
              console.info(error.message)
            });
          }, err => {
            console.log(err)
          })
        } else {
          this.$store.commit(RECEIVE_HIDE_LOADING);
          this.$swal({
            title: res.data.msg,
            type: 'error',
            showCancelButton: false,
            showConfirmButton: true
          })
        }
      })
    },
    logout() {
      this.$swal({
        title: '로그아웃 하시겠습니까 ?',
        type: 'question',
        confirmButtonText: '로그아웃',
        cancelButtonText: '아니오'
      }).then((res) => {
        if (res.value) {
          this.$store.commit(INIT_USERINFO);
          window.sessionStorage.clear();
          this.$store.state.isLogin = false;
          this.$router.replace("/main")
          this.$store.state.uncheckedMessageCount = 0;
          logout();
        }
      })

    },
    initUserInfo() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      this.$store.dispatch('actionUserInfo')
      setTimeout(() => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
      }, 1000)
    },
    initUncheckedMessage() {
      this.$store.dispatch('actionUnCheckedMessageCount')
    },
    changePoint() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      changePoint().then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        if (!res.data.success) {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            confirmButtonText: '확인',
            showCancelButton: false,
            showConfirmButton: true
          })
        } else {
          this.initUserInfo()
        }
      })
    },
    refreshCasinoCash() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      getAccountBalance().then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        this.$set(this.$store.state.userInfo, 'casinocash', res.data.data)
      })
    }
  },
  created() {

  }
}
</script>

<style scoped>
.user_box {
  width: 100%;
  padding: 0px;
}

.user_box .logininfo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  --border: 1px solid #dddddd;
  --background-color: var(--rightSectionBg);
  background-color: var(--logininfoSectionBg);
}

.user_box .logininfo .infoleft {
  width: 30%;
}

.user_box .logininfo .inforight {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: start;
  color: #000000;
  box-sizing: border-box;
  padding-left: 0px;
}

.user_box .logininfo .inforight .btn-change-point {
  background-color: #c51a1b;
  padding: 2px 5px;
}

.user_box .logininfo .inforight .line {
  width: 100%;
  height: 36px;
  line-height: 36px;
  text-align: left;
  box-sizing: border-box;
  padding-left: 0px;
  font-weight: bold;
}

.user_box .logininfo .inforight .underline {
  --border-bottom: 1px solid #30312c;
}

.user_box .btn_login {
  width: 49%;
  height: 34px;
  border-radius: 2px;
  font-weight: 600;
  color: #fdfdfd;
  background-color: var(--loginBtnBg);
  --border: 2px solid var(--loginBtnBgBorder);
}

.user_box .btn_register {
  width: 49%;
  height: 34px;
  border-radius: 2px;
  font-weight: 600;
  color: #353535;
  background-color: var(--regBtnBg);
  --border: 2px solid var(--regBtnBgBorder);

}

.user_box .btn-changepoint {
  background-color: var(--btn01Bg);
  border-radius: 3px;
  padding: 1px 3px;
  color: var(--btn01Color);
}

.user_box .btn-loch {
  background-color: var(--btn01Bg);
  border-radius: 3px;
  padding: 3px 3px;
  color: var(--btn01Color);
  display: none;
}

.user_box .btn-userinfo {
  background-color: var(--btn01Bg);
  border-radius: 3px;
  padding: 3px 3px;
  color: var(--btn01Color);
}

.user_box .txt-nickname {
  color: #ffffff;
}

.user_box .txt-cash {
  color: #ff973e;
  cursor: pointer;
}


@media screen and (max-width: 1024px) {
  .user_box {
    --display: none !important;
    padding-top: 8px !important;
  }

  .user_box .btn-loch {
    display: inline-block !important;
  }

  .right_login {
    margin-top: 10px !important;
  }

  .right_login input {
    height: 38px !important;
    line-height: 38px !important;
  }

  .right_login .button_panel button {
    line-height: 38px !important;
  }

}


</style>